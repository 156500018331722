import 'styled-components';
import React from 'react';
import { Link, PageProps } from 'gatsby';

import Layout from '../layout/layout';
import SEO from '../shared/seo';
import Button from '../shared/button';

const Home = (props: PageProps) => {
  return (
    <Layout>
      <SEO title="Home" keywords={[`blog`, `habibi`, `javascript`, `react`]} />
      <img style={{ margin: 0 }} src="./hero.jpg" alt="Hero Image" />
      <code>
        {`fn main() {
        println!("Hello World!");
      }`}
      </code>
      <br />
      <code>
        {`func main() {
              fmt.Println("Coding is fun!")
          }`}
      </code>
    </Layout>
  );
};

export default Home;